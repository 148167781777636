import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useFilesApi } from '@/api';
import {
  DeleteFileRequest,
  DownloadFileOperationRequest,
  FileResponse,
  GetFilesEntityTypeEnum,
  UploadFilesRequest,
} from '@/client';

const queryKey = ({ entityId }: { entityId: string }) => ['files', entityId];

const paginationLimit = 15;

export const useGetFiles = ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: GetFilesEntityTypeEnum;
}) => {
  const filesApi = useFilesApi();
  return useInfiniteQuery({
    queryKey: queryKey({ entityId }),
    queryFn: ({ pageParam }) =>
      filesApi.getFiles({
        entityId,
        entityType,
        page: pageParam as number,
        size: paginationLimit,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.files.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 0,
  });
};

export const useUploadFiles = () => {
  const filesApi = useFilesApi();
  const queryClient = useQueryClient();
  return useMutation<FileResponse[], Error, UploadFilesRequest>({
    mutationFn: (uploadFilesRequest) => filesApi.uploadFiles(uploadFilesRequest),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({ queryKey: queryKey({ entityId: variables.entityId }) }),
    // TODO: report error
  });
};

export const useDownloadFile = () => {
  const filesApi = useFilesApi();
  return useMutation<Blob, Error, DownloadFileOperationRequest>({
    mutationFn: (downloadFileRequest) => filesApi.downloadFile(downloadFileRequest),
  });
};

export const useDeleteFile = () => {
  const filesApi = useFilesApi();
  const queryClient = useQueryClient();
  return useMutation<FileResponse, Error, DeleteFileRequest>({
    mutationFn: (deleteFileRequest) => filesApi.deleteFile(deleteFileRequest),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({ queryKey: queryKey({ entityId: variables.entityId }) }),
    // TODO: report error
  });
};
