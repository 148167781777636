import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import FormProvider, { RHFSelect, RHFTextField } from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { ProspectStatusEnum } from '@/constants';
import { useGetProspectById, useUpdateProspect } from '@/hooks/useProspects';

export default function EditProspectModal({
  isOpen,
  onClose,
  prospectId,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  prospectId: string;
}) {
  const { t } = useTranslation();

  const { data: prospect, error, isLoading, isSuccess } = useGetProspectById(prospectId);

  const formMethods = useForm({
    defaultValues: {
      label: prospect?.label || '',
      status: prospect?.status || undefined,
    },
    resolver: yupResolver(
      yup.object().shape({
        label: yup.string().required(t('forms.prospects.fields.labelValidation')),
        status: yup.mixed<ProspectStatusEnum>().oneOf(Object.values(ProspectStatusEnum)),
      }),
    ),
  });

  const { handleSubmit, reset } = formMethods;

  const { isPending, mutate: updateProspect } = useUpdateProspect();

  const onSubmit = handleSubmit((values) => {
    updateProspect(
      { _id: prospectId, label: values.label, status: values.status },
      {
        onSuccess: () => {
          onClose();
          reset();
        },
      },
    );
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{t('modals.editProspect.title')}</DialogTitle>
      <FormProvider methods={formMethods} onSubmit={onSubmit}>
        <DialogContent sx={{ py: 2 }}>
          {error && <Alert severity="error">{error.message}</Alert>}
          {isLoading && <LoadingScreen />}
          {isSuccess && (
            <Stack spacing={2}>
              <RHFTextField name="label" label={t('forms.prospects.fields.label')} />
              <RHFSelect name="status" label={t('forms.prospects.fields.status')}>
                <MenuItem value={ProspectStatusEnum.ACTIVE}>Active</MenuItem>
                <MenuItem value={ProspectStatusEnum.ARCHIVED}>Archived</MenuItem>
              </RHFSelect>
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button disabled={isLoading || isPending} type="submit" variant="contained">
            {t('forms.vendors.buttons.update')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
