/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateVendorRequest,
  ErrorResponse,
  UpdateVendorRequest,
  VendorPageResponse,
  VendorResponse,
} from '../models/index';
import {
    CreateVendorRequestFromJSON,
    CreateVendorRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UpdateVendorRequestFromJSON,
    UpdateVendorRequestToJSON,
    VendorPageResponseFromJSON,
    VendorPageResponseToJSON,
    VendorResponseFromJSON,
    VendorResponseToJSON,
} from '../models/index';

export interface CreateVendorOperationRequest {
    createVendorRequest: CreateVendorRequest;
}

export interface GetVendorRequest {
    id: string;
}

export interface GetVendorsRequest {
    xOrganizationId?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    sortDirection?: GetVendorsSortDirectionEnum;
    searchTerm?: string;
    status?: GetVendorsStatusEnum;
}

export interface UpdateVendorOperationRequest {
    id: string;
    updateVendorRequest: UpdateVendorRequest;
}

/**
 * 
 */
export class VendorsApi extends runtime.BaseAPI {

    /**
     * Create a new Vendor
     */
    async createVendorRaw(requestParameters: CreateVendorOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorResponse>> {
        if (requestParameters['createVendorRequest'] == null) {
            throw new runtime.RequiredError(
                'createVendorRequest',
                'Required parameter "createVendorRequest" was null or undefined when calling createVendor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVendorRequestToJSON(requestParameters['createVendorRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Vendor
     */
    async createVendor(requestParameters: CreateVendorOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorResponse> {
        const response = await this.createVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Vendor by ID
     */
    async getVendorRaw(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getVendor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorResponseFromJSON(jsonValue));
    }

    /**
     * Get a Vendor by ID
     */
    async getVendor(requestParameters: GetVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorResponse> {
        const response = await this.getVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search vendors
     */
    async getVendorsRaw(requestParameters: GetVendorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorPageResponse>> {
        const queryParameters: any = {};

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorPageResponseFromJSON(jsonValue));
    }

    /**
     * Search vendors
     */
    async getVendors(requestParameters: GetVendorsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorPageResponse> {
        const response = await this.getVendorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Vendor
     */
    async updateVendorRaw(requestParameters: UpdateVendorOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VendorResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateVendor().'
            );
        }

        if (requestParameters['updateVendorRequest'] == null) {
            throw new runtime.RequiredError(
                'updateVendorRequest',
                'Required parameter "updateVendorRequest" was null or undefined when calling updateVendor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vendors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateVendorRequestToJSON(requestParameters['updateVendorRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorResponseFromJSON(jsonValue));
    }

    /**
     * Update a Vendor
     */
    async updateVendor(requestParameters: UpdateVendorOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VendorResponse> {
        const response = await this.updateVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetVendorsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetVendorsSortDirectionEnum = typeof GetVendorsSortDirectionEnum[keyof typeof GetVendorsSortDirectionEnum];
/**
 * @export
 */
export const GetVendorsStatusEnum = {
    Active: 'ACTIVE',
    Eligible: 'ELIGIBLE',
    Prospect: 'PROSPECT',
    Inactive: 'INACTIVE'
} as const;
export type GetVendorsStatusEnum = typeof GetVendorsStatusEnum[keyof typeof GetVendorsStatusEnum];
