/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInvitationRequest
 */
export interface UpdateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationRequest
     */
    workspaceId?: string;
}

/**
 * Check if a given object implements the UpdateInvitationRequest interface.
 */
export function instanceOfUpdateInvitationRequest(value: object): value is UpdateInvitationRequest {
    return true;
}

export function UpdateInvitationRequestFromJSON(json: any): UpdateInvitationRequest {
    return UpdateInvitationRequestFromJSONTyped(json, false);
}

export function UpdateInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInvitationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'workspaceId': json['workspaceId'] == null ? undefined : json['workspaceId'],
    };
}

export function UpdateInvitationRequestToJSON(value?: UpdateInvitationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'workspaceId': value['workspaceId'],
    };
}

