/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DownloadFileRequest
 */
export interface DownloadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    entityType: DownloadFileRequestEntityTypeEnum;
}


/**
 * @export
 */
export const DownloadFileRequestEntityTypeEnum = {
    Engagement: 'ENGAGEMENT',
    Vendor: 'VENDOR',
    Workspace: 'WORKSPACE'
} as const;
export type DownloadFileRequestEntityTypeEnum = typeof DownloadFileRequestEntityTypeEnum[keyof typeof DownloadFileRequestEntityTypeEnum];


/**
 * Check if a given object implements the DownloadFileRequest interface.
 */
export function instanceOfDownloadFileRequest(value: object): value is DownloadFileRequest {
    if (!('entityId' in value) || value['entityId'] === undefined) return false;
    if (!('fileId' in value) || value['fileId'] === undefined) return false;
    if (!('filename' in value) || value['filename'] === undefined) return false;
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    return true;
}

export function DownloadFileRequestFromJSON(json: any): DownloadFileRequest {
    return DownloadFileRequestFromJSONTyped(json, false);
}

export function DownloadFileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownloadFileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'fileId': json['fileId'],
        'filename': json['filename'],
        'entityType': json['entityType'],
    };
}

export function DownloadFileRequestToJSON(value?: DownloadFileRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entityId': value['entityId'],
        'fileId': value['fileId'],
        'filename': value['filename'],
        'entityType': value['entityType'],
    };
}

