/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreatedBy } from './CreatedBy';
import {
    CreatedByFromJSON,
    CreatedByFromJSONTyped,
    CreatedByToJSON,
} from './CreatedBy';

/**
 * 
 * @export
 * @interface NoteResponse
 */
export interface NoteResponse {
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    comment: string;
    /**
     * 
     * @type {CreatedBy}
     * @memberof NoteResponse
     */
    createdBy: CreatedBy;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    entityId: string;
    /**
     * 
     * @type {Date}
     * @memberof NoteResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof NoteResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the NoteResponse interface.
 */
export function instanceOfNoteResponse(value: object): value is NoteResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('comment' in value) || value['comment'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('entityId' in value) || value['entityId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function NoteResponseFromJSON(json: any): NoteResponse {
    return NoteResponseFromJSONTyped(json, false);
}

export function NoteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'comment': json['comment'],
        'createdBy': CreatedByFromJSON(json['createdBy']),
        'entityId': json['entityId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function NoteResponseToJSON(value?: NoteResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'comment': value['comment'],
        'createdBy': CreatedByToJSON(value['createdBy']),
        'entityId': value['entityId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

