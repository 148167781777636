/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EngagementResponse
 */
export interface EngagementResponse {
    /**
     * 
     * @type {string}
     * @memberof EngagementResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EngagementResponse
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof EngagementResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof EngagementResponse
     */
    frequency: EngagementResponseFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof EngagementResponse
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof EngagementResponse
     */
    status: EngagementResponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof EngagementResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EngagementResponse
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const EngagementResponseFrequencyEnum = {
    Once: 'ONCE',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Biweekly: 'BIWEEKLY',
    Monthly: 'MONTHLY',
    Annually: 'ANNUALLY'
} as const;
export type EngagementResponseFrequencyEnum = typeof EngagementResponseFrequencyEnum[keyof typeof EngagementResponseFrequencyEnum];

/**
 * @export
 */
export const EngagementResponseStatusEnum = {
    Archived: 'ARCHIVED',
    InProgress: 'IN_PROGRESS',
    Paused: 'PAUSED'
} as const;
export type EngagementResponseStatusEnum = typeof EngagementResponseStatusEnum[keyof typeof EngagementResponseStatusEnum];


/**
 * Check if a given object implements the EngagementResponse interface.
 */
export function instanceOfEngagementResponse(value: object): value is EngagementResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function EngagementResponseFromJSON(json: any): EngagementResponse {
    return EngagementResponseFromJSONTyped(json, false);
}

export function EngagementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngagementResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdBy': json['createdBy'],
        'description': json['description'],
        'frequency': json['frequency'],
        'label': json['label'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function EngagementResponseToJSON(value?: EngagementResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'description': value['description'],
        'frequency': value['frequency'],
        'label': value['label'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

