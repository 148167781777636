import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import { GetFilesEntityTypeEnum } from '@/client';
import Iconify from '@/components/iconify';
import { Upload } from '@/components/upload';
import { useUploadFiles } from '@/hooks/useFiles';

// ----------------------------------------------------------------------

export default function UploadFilesModal({
  entityId,
  entityType,
  open,
  onClose,
}: {
  entityId: string;
  entityType: GetFilesEntityTypeEnum;
  open: boolean;
  onClose: VoidFunction;
}) {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFiles([...files, ...newFiles]);
    },
    [files],
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const { error, isPending, mutate: uploadFiles } = useUploadFiles();

  const handleUpload = () =>
    uploadFiles({ files, entityId, entityType }, { onSuccess: () => onClose() });

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={isPending ? () => true : onClose}
      disableEscapeKeyDown={isPending}
    >
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {t('modals.uploadFiles.title')}
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        <Stack spacing={1}>
          <Upload multiple files={files} onDrop={handleDrop} onRemove={handleRemoveFile} />
          {error && <Alert severity="error">{error.message}</Alert>}
        </Stack>
      </DialogContent>

      <DialogActions>
        {!!files.length && (
          <Button
            disabled={isPending}
            variant="outlined"
            color="inherit"
            onClick={handleRemoveAllFiles}
          >
            {t('modals.uploadFiles.buttons.removeAll')}
          </Button>
        )}

        <Button
          disabled={isPending}
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}
        >
          {t('modals.uploadFiles.buttons.upload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
