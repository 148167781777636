import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';

import { RHFTextField } from '@/components/hook-form';

// TODO
// import TagsSelect from './TagsSelect';

export default function VendorFields({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <RHFTextField name="label" label={t('forms.vendors.fields.label')} />

      {children}

      <RHFTextField
        name="description"
        label={t('forms.vendors.fields.description')}
        multiline
        InputProps={{
          style: { resize: 'vertical', overflow: 'auto', minHeight: '53px', maxHeight: '450px' },
        }}
        inputProps={{ maxLength: 255 }}
      />

      {/* <TagsSelect /> */}
    </Stack>
  );
}
