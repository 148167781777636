import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { PhoneNumberDTOTypeEnum } from '@/client';
import { RHFSelect, RHFTextField } from '@/components/hook-form';
import Iconify from '@/components/iconify';

export default function PhoneNumbers() {
  const { fields, append, remove } = useFieldArray({ name: 'phoneNumbers' });

  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      {fields.map((field, index) => (
        <Stack key={field.id} direction="row" justifyContent="space-between" spacing={2}>
          <RHFSelect
            label={t('forms.contacts.fields.phoneNumberType')}
            name={`phoneNumbers.${index}.type`}
          >
            <MenuItem value={PhoneNumberDTOTypeEnum.Mobile}>
              {t('forms.contacts.options.mobile')}
            </MenuItem>
            <MenuItem value={PhoneNumberDTOTypeEnum.Work}>
              {t('forms.contacts.options.work')}
            </MenuItem>
            <MenuItem value={PhoneNumberDTOTypeEnum.Home}>
              {t('forms.contacts.options.home')}
            </MenuItem>
          </RHFSelect>
          <RHFTextField
            label={t('forms.contacts.fields.phoneNumber')}
            name={`phoneNumbers.${index}.phoneNumber`}
          />
          <IconButton aria-label="remove-phone-number" onClick={() => remove(index)}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </Stack>
      ))}
      <Stack direction="row" justifyContent="flex-end">
        <Button
          onClick={() => append({ phoneNumber: '', type: PhoneNumberDTOTypeEnum.Mobile })}
          // TODO: ALIGN ON ICONS!!!!
          startIcon={<Iconify icon="mingcute:add-line" />}
          variant="contained"
        >
          {t('forms.contacts.buttons.addPhoneNumber')}
        </Button>
      </Stack>
    </Stack>
  );
}
