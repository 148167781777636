import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useNotesApi } from '@/api';
import {
  CreateNoteRequest,
  DeleteNoteRequest,
  GetNotesRequest,
  NotePageResponse,
  NoteResponse,
  UpdateNoteOperationRequest,
} from '@/client';

const queryKey = ({ entityId }: { entityId: string }) => ['notes', entityId];

const paginationLimit = 15;

export const useGetNotes = ({ entityId, entityType }: GetNotesRequest) => {
  const notesApi = useNotesApi();
  return useInfiniteQuery<NotePageResponse, Error>({
    queryKey: queryKey({ entityId }),
    queryFn: ({ pageParam }) =>
      notesApi.getNotes({ entityId, entityType, page: pageParam as number, size: 15 }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.notes.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 0,
  });
};

export const useCreateNote = () => {
  const notesApi = useNotesApi();
  const queryClient = useQueryClient();
  return useMutation<NoteResponse, Error, CreateNoteRequest>({
    mutationFn: (createNoteRequest) => notesApi.createNote({ createNoteRequest }),
    onSuccess: ({ entityId }) =>
      queryClient.invalidateQueries({
        queryKey: queryKey({ entityId }),
      }),
    // TODO: reportError
  });
};

export const useUpdateNote = () => {
  const notesApi = useNotesApi();
  const queryClient = useQueryClient();
  return useMutation<NoteResponse, Error, UpdateNoteOperationRequest>({
    mutationFn: ({ id, updateNoteRequest }) => notesApi.updateNote({ id, updateNoteRequest }),
    onSuccess: ({ entityId }) =>
      queryClient.invalidateQueries({ queryKey: queryKey({ entityId }) }),
  });
};

export const useDeleteNote = () => {
  const queryClient = useQueryClient();
  const notesApi = useNotesApi();
  return useMutation<NoteResponse, Error, DeleteNoteRequest>({
    mutationFn: ({ id }) => notesApi.deleteNote({ id }),
    onSuccess: ({ entityId }) =>
      queryClient.invalidateQueries({ queryKey: queryKey({ entityId }) }),
  });
};
