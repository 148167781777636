/* eslint-disable @typescript-eslint/no-redeclare */

export const DisplayType = {
  Card: 'CARD',
  Table: 'TABLE',
};

export type DisplayType = (typeof DisplayType)[keyof typeof DisplayType];

export const PermissionAction = {
  LIST: 'list',
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  DESTROY: 'destroy',
  ASSIGN: 'assign',
  INVITE: 'invite',
};

export type PermissionAction = (typeof PermissionAction)[keyof typeof PermissionAction];

export const PermissionHandle = {
  FILES: 'files',
  ORGS: 'organizations',
  USERS: 'users',
  USER_GROUPS: 'user_groups',
  VENDORS: 'vendors',
};

export type PermissionHandle = (typeof PermissionHandle)[keyof typeof PermissionHandle];

export const Status = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export type Status = (typeof Status)[keyof typeof Status];

export const StatusOptionTypeEnum = {
  ENGAGEMENT: 'ENGAGEMENT',
  VENDOR: 'VENDOR',
};

export type StatusOptionTypeEnum = (typeof StatusOptionTypeEnum)[keyof typeof StatusOptionTypeEnum];

export const ProspectStatusEnum = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export type ProspectStatusEnum = (typeof ProspectStatusEnum)[keyof typeof ProspectStatusEnum];

export const ProspectVendorStatusEnum = {
  CANDIDATE: 'CANDIDATE',
  IN_REVIEW: 'IN_REVIEW',
  SELECTED: 'SELECTED',
};

export type ProspectVendorStatusEnum =
  (typeof ProspectVendorStatusEnum)[keyof typeof ProspectVendorStatusEnum];

export const TOKEN_KEY = 'venddex-token';

export const ORG_ID_KEY = 'venddex-org-id';

export const PATHS = {
  acceptInvitation: '/activate',
  account: '/account',
  base: '/',
  checkout: '/checkout',
  contacts: '/contacts',
  documents: '/documents',
  engagements: '/engagements',
  forgotPassword: '/reset',
  login: '/login',
  notifications: '/notifications',
  onboarding: '/onboarding',
  payment: '/payment',
  pricing: '/pricing',
  prospects: '/prospects',
  resetPassword: '/reset',
  signUp: '/signup',
  support: '/support',
  upgrade: '/upgrade',
  users: '/users',
};
