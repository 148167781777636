/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EngagementResponse } from './EngagementResponse';
import {
    EngagementResponseFromJSON,
    EngagementResponseFromJSONTyped,
    EngagementResponseToJSON,
} from './EngagementResponse';
import type { AddressDTO } from './AddressDTO';
import {
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './AddressDTO';

/**
 * 
 * @export
 * @interface VendorResponse
 */
export interface VendorResponse {
    /**
     * 
     * @type {string}
     * @memberof VendorResponse
     */
    id: string;
    /**
     * 
     * @type {AddressDTO}
     * @memberof VendorResponse
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof VendorResponse
     */
    description?: string;
    /**
     * 
     * @type {Array<EngagementResponse>}
     * @memberof VendorResponse
     */
    engagements: Array<EngagementResponse>;
    /**
     * 
     * @type {string}
     * @memberof VendorResponse
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof VendorResponse
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof VendorResponse
     */
    status: VendorResponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof VendorResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VendorResponse
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const VendorResponseStatusEnum = {
    Active: 'ACTIVE',
    Eligible: 'ELIGIBLE',
    Prospect: 'PROSPECT',
    Inactive: 'INACTIVE'
} as const;
export type VendorResponseStatusEnum = typeof VendorResponseStatusEnum[keyof typeof VendorResponseStatusEnum];


/**
 * Check if a given object implements the VendorResponse interface.
 */
export function instanceOfVendorResponse(value: object): value is VendorResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('engagements' in value) || value['engagements'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('rating' in value) || value['rating'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function VendorResponseFromJSON(json: any): VendorResponse {
    return VendorResponseFromJSONTyped(json, false);
}

export function VendorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': json['address'] == null ? undefined : AddressDTOFromJSON(json['address']),
        'description': json['description'] == null ? undefined : json['description'],
        'engagements': ((json['engagements'] as Array<any>).map(EngagementResponseFromJSON)),
        'label': json['label'],
        'rating': json['rating'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function VendorResponseToJSON(value?: VendorResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'address': AddressDTOToJSON(value['address']),
        'description': value['description'],
        'engagements': ((value['engagements'] as Array<any>).map(EngagementResponseToJSON)),
        'label': value['label'],
        'rating': value['rating'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

