import { useTranslation } from 'react-i18next';

import { Alert } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { RHFSelect } from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useGetInfiniteVendors } from '@/hooks/useVendors';

export default function VendorSelect({
  isDisabled,
  autoComplete,
  excludedIds,
  onChange,
}: {
  excludedIds?: string[];
  isDisabled?: boolean;
  autoComplete?: boolean;
  onChange?: (event: React.SyntheticEvent, value: { label: string; value: string } | null) => void;
}) {
  const { t } = useTranslation();

  const { data, error, isLoading, isSuccess } = useGetInfiniteVendors({});

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isSuccess) {
    if (autoComplete && onChange) {
      const vendorOptions = data?.pages.map(
        ({ vendors }) =>
          vendors?.reduce(
            (options, vendor) => {
              const { id, label } = vendor;
              if (excludedIds?.includes(id)) {
                return options;
              }
              options.push({
                label,
                value: id,
              });
              return options;
            },
            [{ label: 'Select', value: '' }],
          ) || [],
      );

      return (
        <Autocomplete
          disablePortal
          id="vendor"
          // blurOnSelect
          // clearOnBlur
          clearOnEscape
          options={vendorOptions}
          // TODO
          // @ts-ignore
          onChange={onChange}
          renderInput={(params) => <TextField {...params} fullWidth label="Vendor" />}
          value={null}
        />
      );
    }

    return (
      <RHFSelect
        disabled={isDisabled}
        name="vendor"
        label={t('forms.common.vendor')}
        placeholder={t('forms.common.vendorPlaceholder')}
      >
        {data?.pages.map(({ vendors }) =>
          vendors.map((vendor) => (
            <MenuItem key={vendor.id} value={vendor.id}>
              {vendor.label}
            </MenuItem>
          )),
        )}
      </RHFSelect>
    );
  }
}
