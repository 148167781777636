/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileResponse } from './FileResponse';
import {
    FileResponseFromJSON,
    FileResponseFromJSONTyped,
    FileResponseToJSON,
} from './FileResponse';

/**
 * 
 * @export
 * @interface FilePageResponse
 */
export interface FilePageResponse {
    /**
     * 
     * @type {number}
     * @memberof FilePageResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof FilePageResponse
     */
    files: Array<FileResponse>;
}

/**
 * Check if a given object implements the FilePageResponse interface.
 */
export function instanceOfFilePageResponse(value: object): value is FilePageResponse {
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('files' in value) || value['files'] === undefined) return false;
    return true;
}

export function FilePageResponseFromJSON(json: any): FilePageResponse {
    return FilePageResponseFromJSONTyped(json, false);
}

export function FilePageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilePageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'files': ((json['files'] as Array<any>).map(FileResponseFromJSON)),
    };
}

export function FilePageResponseToJSON(value?: FilePageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalCount': value['totalCount'],
        'files': ((value['files'] as Array<any>).map(FileResponseToJSON)),
    };
}

