import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { EngagementResponseFrequencyEnum } from '@/client';
import { RHFSelect, RHFTextField } from '@/components/hook-form';

import VendorSelect from './VendorSelect';

export default function EngagementFields({ showVendorSelect }: { showVendorSelect: boolean }) {
  const { t } = useTranslation();

  const [frequencyType, setFrequencyType] = useState('ONCE');

  const { setValue } = useFormContext();

  return (
    <Stack spacing={2}>
      <Box>
        <ToggleButtonGroup
          color="primary"
          value={frequencyType}
          exclusive
          onChange={(_, value) => {
            if (value === 'ONCE') {
              setValue('frequency', EngagementResponseFrequencyEnum.Once);
            } else {
              setValue('frequency', '');
            }
            setFrequencyType(value);
          }}
          aria-label="Type"
        >
          <ToggleButton value="ONCE">One Time</ToggleButton>
          <ToggleButton value="RECURRING">Recurring</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {frequencyType === 'RECURRING' && (
        <RHFSelect
          name="frequency"
          label={t('forms.engagements.fields.frequency')}
          placeholder={t('forms.engagements.fields.frequencyPlaceholder')}
        >
          <MenuItem value={EngagementResponseFrequencyEnum.Once} sx={{ display: 'none' }} />
          <MenuItem value={EngagementResponseFrequencyEnum.Daily}>Daily</MenuItem>
          <MenuItem value={EngagementResponseFrequencyEnum.Weekly}>Weekly</MenuItem>
          <MenuItem value={EngagementResponseFrequencyEnum.Biweekly}>Bi-Weekly</MenuItem>
          <MenuItem value={EngagementResponseFrequencyEnum.Monthly}>Monthly</MenuItem>
          <MenuItem value={EngagementResponseFrequencyEnum.Annually}>Annually</MenuItem>
        </RHFSelect>
      )}
      {!showVendorSelect && <VendorSelect />}
      <RHFTextField name="label" label={t('forms.engagements.fields.label')} />
      <RHFTextField name="description" label="Description" multiline minRows={3} />
    </Stack>
  );
  // }
}
