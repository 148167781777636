import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Box from '@mui/material/Box';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import Iconify from '@/components/iconify';
import Label from '@/components/label';
import SearchModalTabs from '@/components/search/SearchModalTabs';
import { useBoolean } from '@/hooks/use-boolean';
import { useEventListener } from '@/hooks/use-event-listener';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const { t } = useTranslation();

  const searchModal = useBoolean();

  const handleClose = useCallback(() => {
    searchModal.onFalse();
    setSearchTerm('');
  }, [searchModal]);

  const theme = useTheme();

  // const lgUp = useResponsive('up', 'lg');

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'k' && event.metaKey) {
      searchModal.onToggle();
      setSearchTerm('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={searchModal.onTrue}>
          <Iconify icon="eva:search-fill" />
        </IconButton>
      </Stack>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={searchModal.value}
        onClose={handleClose}
        // scroll="paper"
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder={t('modals.search.input.placeholder')}
            value={searchTerm}
            onChange={(evt) => {
              setSearchTerm(evt.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        {/* <SearchNotFound query={searchTerm} sx={{ py: 10 }} /> */}
        <DialogContent sx={{ height: '400px' }}>
          <SearchModalTabs closeModal={searchModal.onFalse} searchTerm={debouncedSearchTerm} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(SearchBar);
