import { Configuration, OrganizationsApi, SubscriptionPlansApi, UsersApi } from '@/client';
import { useAppContext } from '@/providers';

const useApiConfig = () => {
  const { currentOrganization, currentUser } = useAppContext();
  return new Configuration({
    basePath: import.meta.env.VITE_API_BASE_PATH,
    headers: {
      Authorization: `Bearer ${currentUser.token}`,
      'X-Organization-Id': currentOrganization.id,
    },
  });
};

export const useOrganizationsApi = () => {
  const config = useApiConfig();
  return new OrganizationsApi(config);
};

export const useSubscriptionPlansApi = () => {
  const config = useApiConfig();
  return new SubscriptionPlansApi(config);
};

export const useUsersApi = () =>
  new UsersApi(
    new Configuration({
      basePath: import.meta.env.VITE_API_BASE_PATH,
    }),
  );
