/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    country?: string;
}

/**
 * Check if a given object implements the AddressDTO interface.
 */
export function instanceOfAddressDTO(value: object): value is AddressDTO {
    return true;
}

export function AddressDTOFromJSON(json: any): AddressDTO {
    return AddressDTOFromJSONTyped(json, false);
}

export function AddressDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'street': json['street'] == null ? undefined : json['street'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zipCode': json['zipCode'] == null ? undefined : json['zipCode'],
        'country': json['country'] == null ? undefined : json['country'],
    };
}

export function AddressDTOToJSON(value?: AddressDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'street': value['street'],
        'city': value['city'],
        'state': value['state'],
        'zipCode': value['zipCode'],
        'country': value['country'],
    };
}

