/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateReviewRequest
 */
export interface UpdateReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateReviewRequest
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateReviewRequest
     */
    rating?: number;
}

/**
 * Check if a given object implements the UpdateReviewRequest interface.
 */
export function instanceOfUpdateReviewRequest(value: object): value is UpdateReviewRequest {
    return true;
}

export function UpdateReviewRequestFromJSON(json: any): UpdateReviewRequest {
    return UpdateReviewRequestFromJSONTyped(json, false);
}

export function UpdateReviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateReviewRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'rating': json['rating'] == null ? undefined : json['rating'],
    };
}

export function UpdateReviewRequestToJSON(value?: UpdateReviewRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
        'rating': value['rating'],
    };
}

