/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    extension: string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    storageRef: string;
    /**
     * 
     * @type {Date}
     * @memberof FileResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FileResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the FileResponse interface.
 */
export function instanceOfFileResponse(value: object): value is FileResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('extension' in value) || value['extension'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('mimeType' in value) || value['mimeType'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    if (!('storageRef' in value) || value['storageRef'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function FileResponseFromJSON(json: any): FileResponse {
    return FileResponseFromJSONTyped(json, false);
}

export function FileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'extension': json['extension'],
        'name': json['name'],
        'mimeType': json['mimeType'],
        'size': json['size'],
        'storageRef': json['storageRef'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function FileResponseToJSON(value?: FileResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'extension': value['extension'],
        'name': value['name'],
        'mimeType': value['mimeType'],
        'size': value['size'],
        'storageRef': value['storageRef'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

