/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatedBy
 */
export interface CreatedBy {
    /**
     * 
     * @type {string}
     * @memberof CreatedBy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedBy
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedBy
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedBy
     */
    lastName: string;
}

/**
 * Check if a given object implements the CreatedBy interface.
 */
export function instanceOfCreatedBy(value: object): value is CreatedBy {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    return true;
}

export function CreatedByFromJSON(json: any): CreatedBy {
    return CreatedByFromJSONTyped(json, false);
}

export function CreatedByFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedBy {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}

export function CreatedByToJSON(value?: CreatedBy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
    };
}

