import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { useProspectsApi } from '@/api';
import { CreateVendorRequest } from '@/client';
import { ProspectVendorStatusEnum } from '@/constants';
import { useCreateVendor } from '@/hooks/useVendors';
import { useAppContext } from '@/providers';
import {
  ICreateProspect,
  IGetProspectsQueryParams,
  IGetProspectsResponse,
  IProspect,
  IUpdateProspect,
  IUpdateProspectVendor,
} from '@/types';

import { useError } from './useError';

export const QUERY_KEY = (
  orgId: string,
  queryParams?: IGetProspectsQueryParams,
  prospectId?: string,
) => ['prospects', orgId, prospectId];

const paginationLimit = '12';

export const useGetInfiniteProspects = (queryParams?: IGetProspectsQueryParams) => {
  const { getProspects } = useProspectsApi();
  const { currentOrganization } = useAppContext();
  return useInfiniteQuery<IGetProspectsResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, queryParams),
    queryFn: ({ pageParam = 0 }) =>
      getProspects({ ...queryParams, limit: paginationLimit, page: pageParam as string }),

    enabled: !!currentOrganization.id,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.prospects.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });
};

export const useGetProspectById = (id: string, options?: UseQueryOptions<IProspect, Error>) => {
  const { getProspectById } = useProspectsApi();
  const { currentOrganization } = useAppContext();
  return useQuery<IProspect, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, {}, id),
    queryFn: () => getProspectById(id),
    ...options,
  });
};

export const useCreateProspect = () => {
  const { createProspect } = useProspectsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<IProspect, Error, ICreateProspect>({
    mutationFn: (prospect) => createProspect(prospect),
    onSuccess: (prospect) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(prospect.organization) });
    },
    onError: (error) => reportError(error),
  });
};

export const useUpdateProspect = () => {
  const { updateProspect } = useProspectsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<IProspect, Error, IUpdateProspect>({
    mutationFn: (updateProspectRequest) => updateProspect(updateProspectRequest),
    onSuccess: (prospect) => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY(prospect.organization, {}, prospect._id),
      });
    },
    onError: (error) => reportError(error),
  });
};

export const useDeleteProspect = () => {
  const { deleteProspect } = useProspectsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<IProspect, Error, string>({
    mutationFn: (prospectId) => deleteProspect(prospectId),
    onSuccess: (prospect) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(prospect.organization) });
    },
    onError: (error) => reportError(error),
  });
};

export const useCreateProspectVendor = () => {
  const {
    error: createVendorError,
    isPending: isCreatingVendor,
    mutateAsync: createVendor,
  } = useCreateVendor();
  const {
    error: updateProspectError,
    isPending: isUpdatingProspect,
    mutateAsync: updateProspect,
  } = useUpdateProspect();

  const error = createVendorError || updateProspectError;
  const isLoading = isCreatingVendor || isUpdatingProspect;

  const createProspectVendor = async ({
    newVendor,
    prospectId,
    status,
    vendors,
  }: {
    newVendor: CreateVendorRequest;
    prospectId: string;
    status: ProspectVendorStatusEnum;
    vendors: IUpdateProspectVendor[];
  }) => {
    const vendor = await createVendor(newVendor);
    return updateProspect({
      _id: prospectId,
      vendors: [
        ...vendors,
        {
          status,
          vendor: vendor.id,
        },
      ],
    });
  };

  return { createProspectVendor, error, isLoading };
};
