/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateInvitationRequest,
  ErrorResponse,
  InvitationPageResponse,
  InvitationResponse,
  UpdateInvitationRequest,
} from '../models/index';
import {
    CreateInvitationRequestFromJSON,
    CreateInvitationRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InvitationPageResponseFromJSON,
    InvitationPageResponseToJSON,
    InvitationResponseFromJSON,
    InvitationResponseToJSON,
    UpdateInvitationRequestFromJSON,
    UpdateInvitationRequestToJSON,
} from '../models/index';

export interface AcceptInvitationRequest {
    id: string;
}

export interface CreateInvitationOperationRequest {
    createInvitationRequest: CreateInvitationRequest;
}

export interface DeleteInvitationRequest {
    id: string;
}

export interface GetInvitationRequest {
    id: string;
}

export interface GetInvitationsRequest {
    page?: number;
    size?: number;
}

export interface UpdateInvitationOperationRequest {
    id: string;
    updateInvitationRequest: UpdateInvitationRequest;
}

/**
 * 
 */
export class InvitationsApi extends runtime.BaseAPI {

    /**
     * Accept an Invitation
     */
    async acceptInvitationRaw(requestParameters: AcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling acceptInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationResponseFromJSON(jsonValue));
    }

    /**
     * Accept an Invitation
     */
    async acceptInvitation(requestParameters: AcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationResponse> {
        const response = await this.acceptInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Invitation
     */
    async createInvitationRaw(requestParameters: CreateInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationResponse>> {
        if (requestParameters['createInvitationRequest'] == null) {
            throw new runtime.RequiredError(
                'createInvitationRequest',
                'Required parameter "createInvitationRequest" was null or undefined when calling createInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInvitationRequestToJSON(requestParameters['createInvitationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Invitation
     */
    async createInvitation(requestParameters: CreateInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationResponse> {
        const response = await this.createInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an Invitation by ID
     */
    async deleteInvitationRaw(requestParameters: DeleteInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Invitation by ID
     */
    async deleteInvitation(requestParameters: DeleteInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationResponse> {
        const response = await this.deleteInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an Invitation by ID
     */
    async getInvitationRaw(requestParameters: GetInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationResponseFromJSON(jsonValue));
    }

    /**
     * Get an Invitation by ID
     */
    async getInvitation(requestParameters: GetInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationResponse> {
        const response = await this.getInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Invitations
     */
    async getInvitationsRaw(requestParameters: GetInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationPageResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationPageResponseFromJSON(jsonValue));
    }

    /**
     * Get Invitations
     */
    async getInvitations(requestParameters: GetInvitationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationPageResponse> {
        const response = await this.getInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an Invitation
     */
    async updateInvitationRaw(requestParameters: UpdateInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateInvitation().'
            );
        }

        if (requestParameters['updateInvitationRequest'] == null) {
            throw new runtime.RequiredError(
                'updateInvitationRequest',
                'Required parameter "updateInvitationRequest" was null or undefined when calling updateInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInvitationRequestToJSON(requestParameters['updateInvitationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationResponseFromJSON(jsonValue));
    }

    /**
     * Update an Invitation
     */
    async updateInvitation(requestParameters: UpdateInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationResponse> {
        const response = await this.updateInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
