// import { useCallback } from 'react';
// import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { Alert } from '@mui/material';

import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

import PhoneNumbers from '@/components/forms/PhoneNumbers';
import { RHFTextField } from '@/components/hook-form';
// import { LoadingScreen } from '@/components/loading-screen';
// import { useGetEngagements } from '@/hooks/useEngagements';
// import { fData } from '@/utils/format-number';
//
// import EngagementsSelect from './EngagementsSelect';
// import VendorSelect from './VendorSelect';

export default function ContactFields() {
  // const { setValue, watch } = useFormContext();

  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const file = acceptedFiles[0];
  //
  //     // const newFile = Object.assign(file, {
  //     //   preview: URL.createObjectURL(file),
  //     // });
  //
  //     if (file) {
  //       setValue('avatarUrl', '', { shouldValidate: true });
  //     }
  //   },
  //   [setValue],
  // );

  const { t } = useTranslation();

  // const watchVendor = watch('vendor');

  // const {
  //   data: { engagements } = {},
  //   error,
  //   isPending: isIdle,
  //   isLoading,
  //   isSuccess,
  // } = useGetEngagements({ limit: '0', vendor: watchVendor }, !!watchVendor);

  return (
    <Stack spacing={3}>
      <Stack spacing={2} direction="row">
        <RHFTextField name="firstName" label={t('forms.common.firstName')} />
        <RHFTextField name="lastName" label={t('forms.common.lastName')} />
      </Stack>
      <Stack spacing={2} direction="row">
        <RHFTextField name="email" label={t('forms.common.email')} />
        <RHFTextField name="title" label={t('forms.contacts.fields.title')} />
      </Stack>
      <PhoneNumbers />
    </Stack>
  );
}
