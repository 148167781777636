/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EngagementResponse } from './EngagementResponse';
import {
    EngagementResponseFromJSON,
    EngagementResponseFromJSONTyped,
    EngagementResponseToJSON,
} from './EngagementResponse';
import type { PhoneNumberDTO } from './PhoneNumberDTO';
import {
    PhoneNumberDTOFromJSON,
    PhoneNumberDTOFromJSONTyped,
    PhoneNumberDTOToJSON,
} from './PhoneNumberDTO';
import type { VendorResponse } from './VendorResponse';
import {
    VendorResponseFromJSON,
    VendorResponseFromJSONTyped,
    VendorResponseToJSON,
} from './VendorResponse';
import type { AddressDTO } from './AddressDTO';
import {
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './AddressDTO';

/**
 * 
 * @export
 * @interface ContactResponse
 */
export interface ContactResponse {
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    id: string;
    /**
     * 
     * @type {AddressDTO}
     * @memberof ContactResponse
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    lastName: string;
    /**
     * 
     * @type {VendorResponse}
     * @memberof ContactResponse
     */
    vendor: VendorResponse;
    /**
     * 
     * @type {Array<EngagementResponse>}
     * @memberof ContactResponse
     */
    engagements: Array<EngagementResponse>;
    /**
     * 
     * @type {string}
     * @memberof ContactResponse
     */
    title?: string;
    /**
     * 
     * @type {Array<PhoneNumberDTO>}
     * @memberof ContactResponse
     */
    phoneNumbers: Array<PhoneNumberDTO>;
    /**
     * 
     * @type {Date}
     * @memberof ContactResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContactResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the ContactResponse interface.
 */
export function instanceOfContactResponse(value: object): value is ContactResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('vendor' in value) || value['vendor'] === undefined) return false;
    if (!('engagements' in value) || value['engagements'] === undefined) return false;
    if (!('phoneNumbers' in value) || value['phoneNumbers'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ContactResponseFromJSON(json: any): ContactResponse {
    return ContactResponseFromJSONTyped(json, false);
}

export function ContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': json['address'] == null ? undefined : AddressDTOFromJSON(json['address']),
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'vendor': VendorResponseFromJSON(json['vendor']),
        'engagements': ((json['engagements'] as Array<any>).map(EngagementResponseFromJSON)),
        'title': json['title'] == null ? undefined : json['title'],
        'phoneNumbers': ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDTOFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ContactResponseToJSON(value?: ContactResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'address': AddressDTOToJSON(value['address']),
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'vendor': VendorResponseToJSON(value['vendor']),
        'engagements': ((value['engagements'] as Array<any>).map(EngagementResponseToJSON)),
        'title': value['title'],
        'phoneNumbers': ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDTOToJSON)),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

