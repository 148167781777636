/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEngagementRequest
 */
export interface UpdateEngagementRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateEngagementRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEngagementRequest
     */
    frequency?: UpdateEngagementRequestFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEngagementRequest
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEngagementRequest
     */
    status?: UpdateEngagementRequestStatusEnum;
}


/**
 * @export
 */
export const UpdateEngagementRequestFrequencyEnum = {
    Once: 'ONCE',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Biweekly: 'BIWEEKLY',
    Monthly: 'MONTHLY',
    Annually: 'ANNUALLY'
} as const;
export type UpdateEngagementRequestFrequencyEnum = typeof UpdateEngagementRequestFrequencyEnum[keyof typeof UpdateEngagementRequestFrequencyEnum];

/**
 * @export
 */
export const UpdateEngagementRequestStatusEnum = {
    Archived: 'ARCHIVED',
    InProgress: 'IN_PROGRESS',
    Paused: 'PAUSED'
} as const;
export type UpdateEngagementRequestStatusEnum = typeof UpdateEngagementRequestStatusEnum[keyof typeof UpdateEngagementRequestStatusEnum];


/**
 * Check if a given object implements the UpdateEngagementRequest interface.
 */
export function instanceOfUpdateEngagementRequest(value: object): value is UpdateEngagementRequest {
    return true;
}

export function UpdateEngagementRequestFromJSON(json: any): UpdateEngagementRequest {
    return UpdateEngagementRequestFromJSONTyped(json, false);
}

export function UpdateEngagementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEngagementRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
        'label': json['label'] == null ? undefined : json['label'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UpdateEngagementRequestToJSON(value?: UpdateEngagementRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'frequency': value['frequency'],
        'label': value['label'],
        'status': value['status'],
    };
}

