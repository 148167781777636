import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { useInvitationsApi } from '@/api';
import {
  AcceptInvitationRequest,
  CreateInvitationOperationRequest,
  DeleteInvitationRequest,
  InvitationPageResponse,
  InvitationResponse,
  UpdateInvitationOperationRequest,
} from '@/client';
import { useAppContext } from '@/providers';

import { useError } from './useError';

const QUERY_KEY = (queryParams?: any, id?: string) => ['invitations', id, { ...queryParams }];

export const useCreateInvitation = () => {
  const invitationsApi = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<InvitationResponse, Error, CreateInvitationOperationRequest>({
    mutationFn: ({ createInvitationRequest }) =>
      invitationsApi.createInvitation({
        createInvitationRequest,
      }),
    onSuccess: ({ recipientEmail }) => {
      posthog.capture('invitation_sent', { recipientEmail });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
    },
    onError: (error) => reportError(error),
  });
};

export const useGetInvitationById = (id: string) => {
  const invitationsApi = useInvitationsApi();
  return useQuery<InvitationResponse, Error>({
    queryKey: QUERY_KEY(id),
    queryFn: () => invitationsApi.getInvitation({ id }),
  });
};

const paginationLimit = '10';

export const useGetInfiniteInvitations = (queryParams?: any) => {
  const invitationsApi = useInvitationsApi();
  const { currentOrganization } = useAppContext();
  return useInfiniteQuery<InvitationPageResponse, Error>({
    queryKey: QUERY_KEY(queryParams),
    queryFn: ({ pageParam }) =>
      invitationsApi.getInvitations({
        ...queryParams,
        page: pageParam as string,
        size: paginationLimit,
      }),
    enabled: !!currentOrganization.id,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.invitations.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 0,
  });
};

export const useUpdateInvitation = () => {
  const invitationsApi = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<InvitationResponse, Error, UpdateInvitationOperationRequest>({
    mutationFn: ({ id, updateInvitationRequest }) =>
      invitationsApi.updateInvitation({ id, updateInvitationRequest }),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}) });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}, id) });
    },
    onError: (error) => reportError(error),
  });
};

export const useDeleteInvitation = () => {
  const invitationsApi = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<InvitationResponse, Error, DeleteInvitationRequest, unknown>({
    mutationFn: ({ id }) => invitationsApi.deleteInvitation({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}) });
    },
    onError: (error) => reportError(error),
  });
};

export const useAcceptInvitation = () => {
  const invitationsApi = useInvitationsApi();
  const queryClient = useQueryClient();
  const { reportError } = useError();
  return useMutation<InvitationResponse, Error, AcceptInvitationRequest>({
    mutationFn: ({ id }) => invitationsApi.acceptInvitation({ id }),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}) });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY({}, id) });
    },
    onError: (error) => reportError(error),
  });
};
