import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import { useUpdateNote } from '@/hooks/useNotes';

export default function EditNoteModal({
  comment,
  id,
  isOpen,
  onClose,
}: {
  comment: string;
  id: string;
  isOpen: boolean;
  onClose: VoidFunction;
}) {
  const formMethods = useForm({
    defaultValues: {
      comment,
    },
    resolver: yupResolver(yup.object().shape({ comment: yup.string().required() })),
  });

  const { handleSubmit, reset } = formMethods;

  const { isPending, mutate: updateNote } = useUpdateNote();

  const onSubmit = handleSubmit((values) => {
    updateNote(
      { id, updateNoteRequest: { comment: values.comment } },
      {
        onSuccess: () => {
          onClose();
          reset();
        },
      },
    );
  });

  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{t('modals.editNote.title')}</DialogTitle>
      <FormProvider methods={formMethods} onSubmit={onSubmit}>
        <DialogContent>
          <RHFTextField
            name="comment"
            multiline
            InputProps={{
              style: {
                resize: 'vertical',
                overflow: 'auto',
                minHeight: '53px',
                maxHeight: '450px',
              },
            }}
            inputProps={{ maxLength: 255 }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button disabled={isPending} type="submit" variant="contained">
            {t('forms.vendors.buttons.update')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
