/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VendorSearchResponse
 */
export interface VendorSearchResponse {
    /**
     * 
     * @type {string}
     * @memberof VendorSearchResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VendorSearchResponse
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof VendorSearchResponse
     */
    engagementCount: number;
    /**
     * 
     * @type {string}
     * @memberof VendorSearchResponse
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof VendorSearchResponse
     */
    organizationId: string;
    /**
     * 
     * @type {number}
     * @memberof VendorSearchResponse
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof VendorSearchResponse
     */
    status: VendorSearchResponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof VendorSearchResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VendorSearchResponse
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const VendorSearchResponseStatusEnum = {
    Active: 'ACTIVE',
    Eligible: 'ELIGIBLE',
    Prospect: 'PROSPECT',
    Inactive: 'INACTIVE'
} as const;
export type VendorSearchResponseStatusEnum = typeof VendorSearchResponseStatusEnum[keyof typeof VendorSearchResponseStatusEnum];


/**
 * Check if a given object implements the VendorSearchResponse interface.
 */
export function instanceOfVendorSearchResponse(value: object): value is VendorSearchResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('engagementCount' in value) || value['engagementCount'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('rating' in value) || value['rating'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function VendorSearchResponseFromJSON(json: any): VendorSearchResponse {
    return VendorSearchResponseFromJSONTyped(json, false);
}

export function VendorSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'engagementCount': json['engagementCount'],
        'label': json['label'],
        'organizationId': json['organizationId'],
        'rating': json['rating'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function VendorSearchResponseToJSON(value?: VendorSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'description': value['description'],
        'engagementCount': value['engagementCount'],
        'label': value['label'],
        'organizationId': value['organizationId'],
        'rating': value['rating'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

