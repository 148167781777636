/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneNumberDTO
 */
export interface PhoneNumberDTO {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberDTO
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberDTO
     */
    type: PhoneNumberDTOTypeEnum;
}


/**
 * @export
 */
export const PhoneNumberDTOTypeEnum = {
    Home: 'HOME',
    Mobile: 'MOBILE',
    Work: 'WORK'
} as const;
export type PhoneNumberDTOTypeEnum = typeof PhoneNumberDTOTypeEnum[keyof typeof PhoneNumberDTOTypeEnum];


/**
 * Check if a given object implements the PhoneNumberDTO interface.
 */
export function instanceOfPhoneNumberDTO(value: object): value is PhoneNumberDTO {
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function PhoneNumberDTOFromJSON(json: any): PhoneNumberDTO {
    return PhoneNumberDTOFromJSONTyped(json, false);
}

export function PhoneNumberDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumberDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'],
        'type': json['type'],
    };
}

export function PhoneNumberDTOToJSON(value?: PhoneNumberDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phoneNumber': value['phoneNumber'],
        'type': value['type'],
    };
}

