/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DownloadFileRequest,
  ErrorResponse,
  FilePageResponse,
  FileResponse,
} from '../models/index';
import {
    DownloadFileRequestFromJSON,
    DownloadFileRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FilePageResponseFromJSON,
    FilePageResponseToJSON,
    FileResponseFromJSON,
    FileResponseToJSON,
} from '../models/index';

export interface DeleteFileRequest {
    entityType: DeleteFileEntityTypeEnum;
    entityId: string;
    fileId: string;
    xOrganizationId?: string;
}

export interface DownloadFileOperationRequest {
    downloadFileRequest: DownloadFileRequest;
    xOrganizationId?: string;
}

export interface GetFilesRequest {
    entityId: string;
    entityType: GetFilesEntityTypeEnum;
    page?: number;
    size?: number;
}

export interface UploadFilesRequest {
    entityType: UploadFilesEntityTypeEnum;
    entityId: string;
    files: Array<Blob>;
    xOrganizationId?: string;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Delete file
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileResponse>> {
        if (requestParameters['entityType'] == null) {
            throw new runtime.RequiredError(
                'entityType',
                'Required parameter "entityType" was null or undefined when calling deleteFile().'
            );
        }

        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling deleteFile().'
            );
        }

        if (requestParameters['fileId'] == null) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling deleteFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/{entityType}/{entityId}/{fileId}`.replace(`{${"entityType"}}`, encodeURIComponent(String(requestParameters['entityType']))).replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters['entityId']))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters['fileId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * Delete file
     */
    async deleteFile(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileResponse> {
        const response = await this.deleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download file
     */
    async downloadFileRaw(requestParameters: DownloadFileOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['downloadFileRequest'] == null) {
            throw new runtime.RequiredError(
                'downloadFileRequest',
                'Required parameter "downloadFileRequest" was null or undefined when calling downloadFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadFileRequestToJSON(requestParameters['downloadFileRequest']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download file
     */
    async downloadFile(requestParameters: DownloadFileOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Files for an Entity
     */
    async getFilesRaw(requestParameters: GetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilePageResponse>> {
        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling getFiles().'
            );
        }

        if (requestParameters['entityType'] == null) {
            throw new runtime.RequiredError(
                'entityType',
                'Required parameter "entityType" was null or undefined when calling getFiles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['entityId'] != null) {
            queryParameters['entityId'] = requestParameters['entityId'];
        }

        if (requestParameters['entityType'] != null) {
            queryParameters['entityType'] = requestParameters['entityType'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilePageResponseFromJSON(jsonValue));
    }

    /**
     * Get Files for an Entity
     */
    async getFiles(requestParameters: GetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilePageResponse> {
        const response = await this.getFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload files
     */
    async uploadFilesRaw(requestParameters: UploadFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileResponse>>> {
        if (requestParameters['entityType'] == null) {
            throw new runtime.RequiredError(
                'entityType',
                'Required parameter "entityType" was null or undefined when calling uploadFiles().'
            );
        }

        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling uploadFiles().'
            );
        }

        if (requestParameters['files'] == null) {
            throw new runtime.RequiredError(
                'files',
                'Required parameter "files" was null or undefined when calling uploadFiles().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['files'] != null) {
            requestParameters['files'].forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/files/{entityType}/{entityId}`.replace(`{${"entityType"}}`, encodeURIComponent(String(requestParameters['entityType']))).replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters['entityId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileResponseFromJSON));
    }

    /**
     * Upload files
     */
    async uploadFiles(requestParameters: UploadFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileResponse>> {
        const response = await this.uploadFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const DeleteFileEntityTypeEnum = {
    Engagement: 'ENGAGEMENT',
    Vendor: 'VENDOR',
    Workspace: 'WORKSPACE'
} as const;
export type DeleteFileEntityTypeEnum = typeof DeleteFileEntityTypeEnum[keyof typeof DeleteFileEntityTypeEnum];
/**
 * @export
 */
export const GetFilesEntityTypeEnum = {
    Engagement: 'ENGAGEMENT',
    Vendor: 'VENDOR',
    Workspace: 'WORKSPACE'
} as const;
export type GetFilesEntityTypeEnum = typeof GetFilesEntityTypeEnum[keyof typeof GetFilesEntityTypeEnum];
/**
 * @export
 */
export const UploadFilesEntityTypeEnum = {
    Engagement: 'ENGAGEMENT',
    Vendor: 'VENDOR',
    Workspace: 'WORKSPACE'
} as const;
export type UploadFilesEntityTypeEnum = typeof UploadFilesEntityTypeEnum[keyof typeof UploadFilesEntityTypeEnum];
