import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useVendorsApi } from '@/api';
import {
  CreateVendorRequest,
  GetVendorsRequest,
  UpdateVendorOperationRequest,
  VendorPageResponse,
  VendorResponse,
} from '@/client';
import { useAppContext } from '@/providers';

import { useError } from './useError';

export const QUERY_KEY = (orgId: string, queryParams?: GetVendorsRequest, vendorId?: string) => [
  'vendors',
  { ...queryParams },
  orgId,
  vendorId,
];

const paginationLimit = 15;

export const useGetInfiniteVendors = ({ searchTerm, sortBy, status }: GetVendorsRequest) => {
  const vendorsApi = useVendorsApi();
  const { currentOrganization } = useAppContext();
  const sortDirection = sortBy === 'label' ? 'ASC' : 'DESC';
  return useInfiniteQuery<VendorPageResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, { searchTerm, sortBy, sortDirection, status }),
    queryFn: ({ pageParam }) =>
      vendorsApi.getVendors({
        limit: paginationLimit,
        offset: pageParam as number,
        searchTerm,
        sortBy,
        sortDirection,
        status,
        xOrganizationId: currentOrganization.id,
      }),
    enabled: !!currentOrganization.id,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.vendors.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 0,
  });
};

export const useGetVendorById = (id: string) => {
  const vendorsApi = useVendorsApi();
  const { currentOrganization } = useAppContext();
  return useQuery<VendorResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, {}, id),
    queryFn: () => vendorsApi.getVendor({ id }),
  });
};

export const useCreateVendor = () => {
  const queryClient = useQueryClient();
  const vendorsApi = useVendorsApi();
  const { reportError } = useError();
  const { currentOrganization } = useAppContext();
  return useMutation<VendorResponse, Error, CreateVendorRequest, unknown>({
    mutationFn: (createVendorRequest) => vendorsApi.createVendor({ createVendorRequest }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id) });
      // queryClient.invalidateQueries({ queryKey: TAGS_QUERY_KEY(organization) });
    },
    onError: (error) => reportError(error),
  });
};

export const useUpdateVendor = () => {
  const queryClient = useQueryClient();
  const vendorsApi = useVendorsApi();
  const { reportError } = useError();
  const { currentOrganization } = useAppContext();
  return useMutation<VendorResponse, Error, UpdateVendorOperationRequest, unknown>({
    mutationFn: (updateVendorRequest) => vendorsApi.updateVendor(updateVendorRequest),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id, {}) });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id, {}, id) });
      // queryClient.invalidateQueries({ queryKey: TAGS_QUERY_KEY(organization) });
    },
    onError: (error) => reportError(error),
  });
};

// export const useDeleteVendor = () => {
//   const queryClient = useQueryClient();
//   const vendorsApi = useVendorsApi();
//   const { reportError } = useError();
//   return useMutation<IVendor, Error, { id: string }, unknown>({
//     mutationFn: ({ id }) => vendorsApi,
//     onSuccess: ({ organization }) => {
//       queryClient.invalidateQueries({ queryKey: QUERY_KEY(organization) });
//     },
//     onError: (error) => reportError(error),
//   });
// };
