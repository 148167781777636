import {
  Configuration,
  ContactsApi,
  EngagementsApi,
  FilesApi,
  InvitationsApi,
  NotesApi,
  ReviewsApi,
  VendorsApi,
} from '@/client';
import { useAppContext } from '@/providers';

export * from './auth';
export * from './engagements';
export * from './organizations';
export * from './payment-methods';
export * from './prospects';
export * from './search';
export * from './sessions';
export * from './subscription-plans';
export * from './subscriptions';
export * from './tags';
export * from './user-groups';
export * from './users';

const useApiConfig = () => {
  const { currentOrganization, currentUser } = useAppContext();
  return new Configuration({
    basePath: import.meta.env.VITE_API_BASE_PATH,
    headers: {
      Authorization: `Bearer ${currentUser.token}`,
      'X-Organization-Id': currentOrganization.id,
    },
  });
};

export const useContactsApi = () => {
  const config = useApiConfig();
  return new ContactsApi(config);
};

export const useEngagementsApi = () => {
  const config = useApiConfig();
  return new EngagementsApi(config);
};

export const useFilesApi = () => {
  const config = useApiConfig();
  return new FilesApi(config);
};

export const useInvitationsApi = () => {
  const config = useApiConfig();
  return new InvitationsApi(config);
};

export const useNotesApi = () => {
  const config = useApiConfig();
  return new NotesApi(config);
};

export const useReviewsApi = () => {
  const config = useApiConfig();
  return new ReviewsApi(config);
};

export const useVendorsApi = () => {
  const config = useApiConfig();
  return new VendorsApi(config);
};
