import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useUsersApi as useLegacyUsersApi, useOrganizationApi } from '@/api';
import { useUsersApi } from '@/api/useUsers';
import { type CreateUserRequest, UserResponse } from '@/client';
import { useAppContext } from '@/providers';
import { IGetUsersQueryParams, IGetUsersResponse, IUpdateUserRequest, IUser } from '@/types';

import { useError } from './useError';

const QUERY_KEY = (orgId: string, queryParams?: IGetUsersQueryParams, userId?: string) => [
  'users',
  queryParams,
  orgId,
  userId,
];

export const useCreateUser = () => {
  const { reportError } = useError();
  const usersApi = useUsersApi();
  return useMutation<UserResponse, Error, CreateUserRequest, unknown>({
    mutationFn: (createUserRequest) => usersApi.createUser({ createUserRequest }),
    onError: (error) => reportError(error),
  });
};

const paginationLimit = '15';

export const useGetInfiniteUsers = (queryParams?: IGetUsersQueryParams) => {
  const { getUsers } = useLegacyUsersApi();
  const { currentOrganization } = useAppContext();
  return useInfiniteQuery<IGetUsersResponse, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, queryParams),
    queryFn: ({ pageParam }) =>
      getUsers({ ...queryParams, limit: paginationLimit, page: pageParam as string }),
    enabled: !!currentOrganization.id,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.users.length === Number(paginationLimit) ? allPages.length + 1 : undefined,
    initialPageParam: 1,
  });
};

export const useGetUserById = (id: string) => {
  const { getUserById } = useLegacyUsersApi();
  const { currentOrganization } = useAppContext();
  return useQuery<IUser, Error>({
    queryKey: QUERY_KEY(currentOrganization.id, {}, id),
    queryFn: () => getUserById(id),
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { currentOrganization, setCurrentUser } = useAppContext();
  const { updateUser } = useLegacyUsersApi();
  const { reportError } = useError();
  return useMutation<IUser, Error, IUpdateUserRequest, unknown>({
    mutationFn: (user) => updateUser(user),
    onSuccess: (user) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id, {}, user._id) });
      setCurrentUser(user as any);
    },
    onError: (error) => reportError(error),
  });
};

export const useUpdateUserGroup = () => {
  const queryClient = useQueryClient();
  const { currentOrganization } = useAppContext();
  const { updateUser } = useOrganizationApi();
  const { reportError } = useError();
  return useMutation<IUser, Error, { userId: string; userGroupId: string }, unknown>({
    mutationFn: ({ userGroupId, userId }) =>
      updateUser({ orgId: currentOrganization.id, userId, userGroupId }),
    onSuccess: ({ _id }) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id) });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id, {}, _id) });
    },
    onError: (error) => reportError(error),
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const { currentOrganization } = useAppContext();
  const { deleteUser } = useLegacyUsersApi();
  const { reportError } = useError();
  return useMutation<IUser, Error, { id: string }, unknown>({
    mutationFn: ({ id }) => deleteUser(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id) });
    },
    onError: (error) => reportError(error),
  });
};

export const useRemoveUser = () => {
  const queryClient = useQueryClient();
  const { currentOrganization } = useAppContext();
  const { removeUser } = useOrganizationApi();
  const { reportError } = useError();
  return useMutation<IUser, Error, { id: string }, unknown>({
    mutationFn: ({ id }) => removeUser({ orgId: currentOrganization.id, userId: id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY(currentOrganization.id) });
    },
    onError: (error) => reportError(error),
  });
};

export const useSendResetPasswordLink = () => {
  const usersApi = useUsersApi();
  const { reportError } = useError();
  return useMutation<void, Error, { email: string }>({
    mutationFn: ({ email }) => usersApi.sendResetPasswordEmail({ resetPasswordRequest: { email } }),
    onError: (error) => reportError(error),
  });
};
