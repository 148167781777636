import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import { ContactResponse } from '@/client';
import FormProvider from '@/components/hook-form';
import useContactSchema from '@/hooks/schemas/useContactSchema';
// import { useUpdateContact } from '@/hooks/useContacts';

import ContactFields from './ContactFields';

export default function UpdateContactForm({ contact }: { contact: ContactResponse }) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      email: contact.email,
      firstName: contact.firstName,
      lastName: contact.lastName,
      phoneNumbers: contact.phoneNumbers,
      title: contact.title,
      vendorId: contact.vendor.id,
    },
    resolver: yupResolver(useContactSchema()),
  });

  const { handleSubmit } = formMethods;

  // const watchVendor = watch('vendor');

  // useEffect(() => {
  //   if (watchVendor !== contact.vendor._id) {
  //     setValue('engagements', []);
  //   }
  // }, [setValue, watchVendor, contact.vendor._id]);

  // const { isPending, mutate: updateContact } = useUpdateContact();

  const isPending = false;

  // const { enqueueSnackbar } = useSnackbar();
  //
  // const engagementIdsMap = useEngagementIdMap({ vendor: watchVendor });

  const onSubmit = handleSubmit((values) => {
    console.log(values);
    // const engagementIds = values.engagements.reduce<string[]>((acc, label) => {
    //   const id = engagementIdsMap.get(label);
    //   if (id) {
    //     acc.push(id);
    //   }
    //   return acc;
    // }, []);
    //
    // updateContact(
    //   { ...values, _id: contact._id, engagements: engagementIds },
    //   {
    //     onSuccess: ({ firstName, lastName }) =>
    //       enqueueSnackbar(`${firstName} ${lastName} has been updated`),
    //   },
    // );
  });

  return (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <ContactFields />
        <Stack direction="row" justifyContent="flex-end">
          <LoadingButton loading={isPending} variant="contained" type="submit">
            {t('forms.contacts.buttons.update')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
