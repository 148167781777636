/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressDTO } from './AddressDTO';
import {
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './AddressDTO';

/**
 * 
 * @export
 * @interface UpdateVendorRequest
 */
export interface UpdateVendorRequest {
    /**
     * 
     * @type {AddressDTO}
     * @memberof UpdateVendorRequest
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorRequest
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorRequest
     */
    status?: UpdateVendorRequestStatusEnum;
}


/**
 * @export
 */
export const UpdateVendorRequestStatusEnum = {
    Active: 'ACTIVE',
    Eligible: 'ELIGIBLE',
    Prospect: 'PROSPECT',
    Inactive: 'INACTIVE'
} as const;
export type UpdateVendorRequestStatusEnum = typeof UpdateVendorRequestStatusEnum[keyof typeof UpdateVendorRequestStatusEnum];


/**
 * Check if a given object implements the UpdateVendorRequest interface.
 */
export function instanceOfUpdateVendorRequest(value: object): value is UpdateVendorRequest {
    return true;
}

export function UpdateVendorRequestFromJSON(json: any): UpdateVendorRequest {
    return UpdateVendorRequestFromJSONTyped(json, false);
}

export function UpdateVendorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateVendorRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : AddressDTOFromJSON(json['address']),
        'description': json['description'] == null ? undefined : json['description'],
        'label': json['label'] == null ? undefined : json['label'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UpdateVendorRequestToJSON(value?: UpdateVendorRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': AddressDTOToJSON(value['address']),
        'description': value['description'],
        'label': value['label'],
        'status': value['status'],
    };
}

