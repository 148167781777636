import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import FormProvider, { RHFTextField } from '@/components/hook-form';
import { useSnackbar } from '@/components/snackbar';
import { useInviteUserSchema } from '@/hooks/schemas/useInviteUserSchema';
import { useCreateInvitation } from '@/hooks/useInvitations';

export default function InviteUserModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
}) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(useInviteUserSchema()),
  });

  const { handleSubmit, reset } = formMethods;

  const { isPending, mutate: createInvitation } = useCreateInvitation();

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(({ email }) => {
    createInvitation(
      { createInvitationRequest: { email } },
      {
        onSuccess: ({ recipientEmail }) => {
          reset();
          onClose();
          enqueueSnackbar(t('forms.invite.successMessage', { email: recipientEmail }));
        },
      },
    );
  });

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={isPending ? () => true : onClose}
      disableEscapeKeyDown={isPending}
    >
      <DialogTitle>{t('modals.inviteUser.title')}</DialogTitle>
      <FormProvider methods={formMethods} onSubmit={onSubmit}>
        <DialogContent sx={{ py: 2 }}>
          <Stack spacing={2}>
            <RHFTextField name="email" label={t('forms.common.email')} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <LoadingButton loading={isPending} variant="contained" type="submit">
            {t('forms.invite.buttons.create')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
