/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationResponse } from './OrganizationResponse';
import {
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastName: string;
    /**
     * 
     * @type {Array<OrganizationResponse>}
     * @memberof UserResponse
     */
    organizations: Array<OrganizationResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    token: string;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the UserResponse interface.
 */
export function instanceOfUserResponse(value: object): value is UserResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('organizations' in value) || value['organizations'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'organizations': ((json['organizations'] as Array<any>).map(OrganizationResponseFromJSON)),
        'token': json['token'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'organizations': ((value['organizations'] as Array<any>).map(OrganizationResponseToJSON)),
        'token': value['token'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

