/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreatedBy } from './CreatedBy';
import {
    CreatedByFromJSON,
    CreatedByFromJSONTyped,
    CreatedByToJSON,
} from './CreatedBy';
import type { OrganizationResponse } from './OrganizationResponse';
import {
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';

/**
 * 
 * @export
 * @interface InvitationResponse
 */
export interface InvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof InvitationResponse
     */
    id: string;
    /**
     * 
     * @type {CreatedBy}
     * @memberof InvitationResponse
     */
    createdBy: CreatedBy;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof InvitationResponse
     */
    organization: OrganizationResponse;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponse
     */
    recipientEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponse
     */
    status: InvitationResponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof InvitationResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvitationResponse
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const InvitationResponseStatusEnum = {
    Accepted: 'ACCEPTED',
    Pending: 'PENDING'
} as const;
export type InvitationResponseStatusEnum = typeof InvitationResponseStatusEnum[keyof typeof InvitationResponseStatusEnum];


/**
 * Check if a given object implements the InvitationResponse interface.
 */
export function instanceOfInvitationResponse(value: object): value is InvitationResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('organization' in value) || value['organization'] === undefined) return false;
    if (!('recipientEmail' in value) || value['recipientEmail'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function InvitationResponseFromJSON(json: any): InvitationResponse {
    return InvitationResponseFromJSONTyped(json, false);
}

export function InvitationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdBy': CreatedByFromJSON(json['createdBy']),
        'organization': OrganizationResponseFromJSON(json['organization']),
        'recipientEmail': json['recipientEmail'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function InvitationResponseToJSON(value?: InvitationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': CreatedByToJSON(value['createdBy']),
        'organization': OrganizationResponseToJSON(value['organization']),
        'recipientEmail': value['recipientEmail'],
        'status': value['status'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

