/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEngagementRequest
 */
export interface CreateEngagementRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEngagementRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEngagementRequest
     */
    frequency: CreateEngagementRequestFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEngagementRequest
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEngagementRequest
     */
    status: CreateEngagementRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEngagementRequest
     */
    vendorId: string;
}


/**
 * @export
 */
export const CreateEngagementRequestFrequencyEnum = {
    Once: 'ONCE',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Biweekly: 'BIWEEKLY',
    Monthly: 'MONTHLY',
    Annually: 'ANNUALLY'
} as const;
export type CreateEngagementRequestFrequencyEnum = typeof CreateEngagementRequestFrequencyEnum[keyof typeof CreateEngagementRequestFrequencyEnum];

/**
 * @export
 */
export const CreateEngagementRequestStatusEnum = {
    Archived: 'ARCHIVED',
    InProgress: 'IN_PROGRESS',
    Paused: 'PAUSED'
} as const;
export type CreateEngagementRequestStatusEnum = typeof CreateEngagementRequestStatusEnum[keyof typeof CreateEngagementRequestStatusEnum];


/**
 * Check if a given object implements the CreateEngagementRequest interface.
 */
export function instanceOfCreateEngagementRequest(value: object): value is CreateEngagementRequest {
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('vendorId' in value) || value['vendorId'] === undefined) return false;
    return true;
}

export function CreateEngagementRequestFromJSON(json: any): CreateEngagementRequest {
    return CreateEngagementRequestFromJSONTyped(json, false);
}

export function CreateEngagementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEngagementRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'frequency': json['frequency'],
        'label': json['label'],
        'status': json['status'],
        'vendorId': json['vendorId'],
    };
}

export function CreateEngagementRequestToJSON(value?: CreateEngagementRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'frequency': value['frequency'],
        'label': value['label'],
        'status': value['status'],
        'vendorId': value['vendorId'],
    };
}

