/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactResponse,
  CreateContactRequest,
  ErrorResponse,
} from '../models/index';
import {
    ContactResponseFromJSON,
    ContactResponseToJSON,
    CreateContactRequestFromJSON,
    CreateContactRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models/index';

export interface CreateContactOperationRequest {
    createContactRequest: CreateContactRequest;
}

export interface DeleteContactRequest {
    id: string;
}

export interface GetContactRequest {
    id: string;
}

export interface GetContactsRequest {
    vendorId?: string;
}

/**
 * 
 */
export class ContactsApi extends runtime.BaseAPI {

    /**
     * Create a new Contact
     */
    async createContactRaw(requestParameters: CreateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactResponse>> {
        if (requestParameters['createContactRequest'] == null) {
            throw new runtime.RequiredError(
                'createContactRequest',
                'Required parameter "createContactRequest" was null or undefined when calling createContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactRequestToJSON(requestParameters['createContactRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Contact
     */
    async createContact(requestParameters: CreateContactOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactResponse> {
        const response = await this.createContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Contact by ID
     */
    async deleteContactRaw(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Contact by ID
     */
    async deleteContact(requestParameters: DeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactResponse> {
        const response = await this.deleteContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Contact by ID
     */
    async getContactRaw(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getContact().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactResponseFromJSON(jsonValue));
    }

    /**
     * Get a Contact by ID
     */
    async getContact(requestParameters: GetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactResponse> {
        const response = await this.getContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getContactsRaw(requestParameters: GetContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContactResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['vendorId'] != null) {
            queryParameters['vendorId'] = requestParameters['vendorId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactResponseFromJSON));
    }

    /**
     */
    async getContacts(requestParameters: GetContactsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContactResponse>> {
        const response = await this.getContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
