import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import { InvitationResponse } from '@/client';
import FormProvider, { RHFTextField } from '@/components/hook-form';
import { LoadingScreen } from '@/components/loading-screen';
import { useInviteUserSchema } from '@/hooks/schemas/useInviteUserSchema';
import { useUpdateInvitation } from '@/hooks/useInvitations';

const EditInvitation = ({
  onClose,
  invitation,
}: {
  onClose: VoidFunction;
  invitation: InvitationResponse;
}) => {
  const formMethods = useForm({
    defaultValues: {
      email: invitation.recipientEmail,
    },
    resolver: yupResolver(useInviteUserSchema()),
  });

  const { handleSubmit, reset } = formMethods;

  const { isPending, mutate: updateInvitation } = useUpdateInvitation();

  const onSubmit = handleSubmit((values) => {
    updateInvitation(
      { id: invitation.id, updateInvitationRequest: { email: values.email } },
      {
        onSuccess: () => {
          reset();
          onClose();
        },
      },
    );
  });

  const { t } = useTranslation();

  return (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <DialogContent sx={{ py: 2 }}>
        <Stack spacing={2}>
          <RHFTextField name="email" label={t('forms.common.email')} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('buttons.cancel')}</Button>
        <LoadingButton loading={isPending} variant="contained" type="submit">
          {t('forms.invite.buttons.update')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
};

export default function EditInvitationModal({
  isOpen,
  onClose,
  invitation,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  invitation: InvitationResponse;
}) {
  const { t } = useTranslation();

  // TODO
  // const { data: invitation, error, isLoading, isSuccess } = useGetInvitationById(invitationId);

  const isLoading = false;
  const isSuccess = true;

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle>{t('modals.editInvitation.title')}</DialogTitle>
      {/* {error && ( */}
      {/*   <DialogContent> */}
      {/*     <Alert severity="error">{error.message}</Alert> */}
      {/*   </DialogContent> */}
      {/* )} */}
      {isLoading && (
        <DialogContent>
          <LoadingScreen />
        </DialogContent>
      )}
      {isSuccess && <EditInvitation onClose={onClose} invitation={invitation} />}
    </Dialog>
  );
}
