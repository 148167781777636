import { useFormContext } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { countries, usStateOptions } from '@/assets/data';
import { RHFAutocomplete, RHFSelect, RHFTextField } from '@/components/hook-form';

export const defaultAddressValues = {
  city: '',
  country: 'United States',
  state: '',
  street: '',
  zipCode: '',
};

export function AddressFields() {
  const { watch } = useFormContext();

  const watchCountry = watch('address.country');

  return (
    <Stack spacing={3}>
      <RHFTextField name="address.street" label="Address" />
      <Stack spacing={2} direction="row">
        <RHFTextField name="address.city" label="City" />
        {watchCountry === 'United States' ? (
          <RHFSelect name="address.state" label="State" placeholder="Choose a State">
            {usStateOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </RHFSelect>
        ) : (
          <RHFTextField name="address.state" label="Region" />
        )}
      </Stack>
      <Stack spacing={2} direction="row">
        <RHFTextField name="address.zipCode" label="Zip Code" fullWidth />
        <RHFAutocomplete
          fullWidth
          name="address.country"
          type="country"
          label="Country"
          placeholder="Choose a country"
          options={countries.map((option) => option.label)}
          getOptionLabel={(option) => option}
        />
      </Stack>
    </Stack>
  );
}
