/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionResponse } from './SubscriptionResponse';
import {
    SubscriptionResponseFromJSON,
    SubscriptionResponseFromJSONTyped,
    SubscriptionResponseToJSON,
} from './SubscriptionResponse';

/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    stripeDefaultPaymentMethodId?: string;
    /**
     * 
     * @type {SubscriptionResponse}
     * @memberof OrganizationResponse
     */
    subscription: SubscriptionResponse;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the OrganizationResponse interface.
 */
export function instanceOfOrganizationResponse(value: object): value is OrganizationResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('subscription' in value) || value['subscription'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function OrganizationResponseFromJSON(json: any): OrganizationResponse {
    return OrganizationResponseFromJSONTyped(json, false);
}

export function OrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'stripeDefaultPaymentMethodId': json['stripeDefaultPaymentMethodId'] == null ? undefined : json['stripeDefaultPaymentMethodId'],
        'subscription': SubscriptionResponseFromJSON(json['subscription']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function OrganizationResponseToJSON(value?: OrganizationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'label': value['label'],
        'stripeDefaultPaymentMethodId': value['stripeDefaultPaymentMethodId'],
        'subscription': SubscriptionResponseToJSON(value['subscription']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

