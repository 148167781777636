/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressDTO } from './AddressDTO';
import {
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './AddressDTO';

/**
 * 
 * @export
 * @interface CreateVendorRequest
 */
export interface CreateVendorRequest {
    /**
     * 
     * @type {AddressDTO}
     * @memberof CreateVendorRequest
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorRequest
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorRequest
     */
    status?: CreateVendorRequestStatusEnum;
}


/**
 * @export
 */
export const CreateVendorRequestStatusEnum = {
    Active: 'ACTIVE',
    Eligible: 'ELIGIBLE',
    Prospect: 'PROSPECT',
    Inactive: 'INACTIVE'
} as const;
export type CreateVendorRequestStatusEnum = typeof CreateVendorRequestStatusEnum[keyof typeof CreateVendorRequestStatusEnum];


/**
 * Check if a given object implements the CreateVendorRequest interface.
 */
export function instanceOfCreateVendorRequest(value: object): value is CreateVendorRequest {
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function CreateVendorRequestFromJSON(json: any): CreateVendorRequest {
    return CreateVendorRequestFromJSONTyped(json, false);
}

export function CreateVendorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVendorRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : AddressDTOFromJSON(json['address']),
        'description': json['description'] == null ? undefined : json['description'],
        'label': json['label'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function CreateVendorRequestToJSON(value?: CreateVendorRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': AddressDTOToJSON(value['address']),
        'description': value['description'],
        'label': value['label'],
        'status': value['status'],
    };
}

