/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEngagementRequest,
  EngagementResponse,
  ErrorResponse,
  UpdateEngagementRequest,
} from '../models/index';
import {
    CreateEngagementRequestFromJSON,
    CreateEngagementRequestToJSON,
    EngagementResponseFromJSON,
    EngagementResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UpdateEngagementRequestFromJSON,
    UpdateEngagementRequestToJSON,
} from '../models/index';

export interface CreateEngagementOperationRequest {
    createEngagementRequest: CreateEngagementRequest;
    xOrganizationId?: string;
}

export interface DeleteEngagementRequest {
    id: string;
}

export interface GetEngagementRequest {
    id: string;
}

export interface UpdateEngagementOperationRequest {
    id: string;
    updateEngagementRequest: UpdateEngagementRequest;
}

/**
 * 
 */
export class EngagementsApi extends runtime.BaseAPI {

    /**
     * Create a new Engagement
     */
    async createEngagementRaw(requestParameters: CreateEngagementOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EngagementResponse>> {
        if (requestParameters['createEngagementRequest'] == null) {
            throw new runtime.RequiredError(
                'createEngagementRequest',
                'Required parameter "createEngagementRequest" was null or undefined when calling createEngagement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/engagements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEngagementRequestToJSON(requestParameters['createEngagementRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngagementResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Engagement
     */
    async createEngagement(requestParameters: CreateEngagementOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EngagementResponse> {
        const response = await this.createEngagementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an Engagement by ID
     */
    async deleteEngagementRaw(requestParameters: DeleteEngagementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EngagementResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEngagement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/engagements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngagementResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Engagement by ID
     */
    async deleteEngagement(requestParameters: DeleteEngagementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EngagementResponse> {
        const response = await this.deleteEngagementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an Engagement by ID
     */
    async getEngagementRaw(requestParameters: GetEngagementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EngagementResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEngagement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/engagements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngagementResponseFromJSON(jsonValue));
    }

    /**
     * Get an Engagement by ID
     */
    async getEngagement(requestParameters: GetEngagementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EngagementResponse> {
        const response = await this.getEngagementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an Engagement
     */
    async updateEngagementRaw(requestParameters: UpdateEngagementOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EngagementResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEngagement().'
            );
        }

        if (requestParameters['updateEngagementRequest'] == null) {
            throw new runtime.RequiredError(
                'updateEngagementRequest',
                'Required parameter "updateEngagementRequest" was null or undefined when calling updateEngagement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/engagements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEngagementRequestToJSON(requestParameters['updateEngagementRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EngagementResponseFromJSON(jsonValue));
    }

    /**
     * Update an Engagement
     */
    async updateEngagement(requestParameters: UpdateEngagementOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EngagementResponse> {
        const response = await this.updateEngagementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
