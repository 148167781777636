import * as yup from 'yup';

export const useAddressSchema = () =>
  yup.object().shape({
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string(),
    country: yup.string(),
  });
