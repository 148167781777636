import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { PhoneNumberDTOTypeEnum } from '@/client';

import { useAddressSchema } from './useAddressSchema';

export default function useContactSchema() {
  const { t } = useTranslation();

  const createPhoneNumberRequestSchema = yup.object().shape({
    phoneNumber: yup.string().required(t('forms.contacts.fields.phoneNumberValidation')),
    // .matches(/^\+?[1-9]\d{1,14}$/, 'Phone number must be a valid E.164 format'),
    type: yup
      .string()
      .oneOf(Object.values(PhoneNumberDTOTypeEnum))
      .required(t('forms.contacts.fields.phoneNumberTypeValidation')),
  });

  return yup.object().shape({
    address: useAddressSchema(),
    email: yup.string().email(t('forms.common.emailValid')).required(),
    firstName: yup.string().required(t('forms.common.firstNameValidation')),
    lastName: yup.string().required(t('forms.common.lastNameValidation')),
    engagementId: yup.string().uuid('Engagement ID must be a valid UUID').optional(),
    vendorId: yup.string().uuid('Vendor ID must be a valid UUID').optional(),
    title: yup.string().optional(),
    phoneNumbers: yup.array().of(createPhoneNumberRequestSchema).optional(),
  });

  // yup.object().shape({
  //   email: yup.string().email().optional(),
  //   firstName: yup.string().required(t('forms.common.firstNameValidation')),
  //   lastName: yup.string().required(t('forms.common.lastNameValidation')),
  //   phoneNumbers: yup
  //     .array(
  //       yup.object().shape({
  //         phoneNumber: yup
  //           .string()
  //           .when('$phoneNumbers', (phoneNumbers, schema) =>
  //             phoneNumbers && phoneNumbers.length > 0
  //               ? schema.required(t('forms.contacts.fields.phoneNumberValidation'))
  //               : schema,
  //           ),
  //         type: yup
  //           .string()
  //           .oneOf(Object.values(CreatePhoneNumberRequestTypeEnum))
  //           .when('$phoneNumbers', (phoneNumbers, schema) =>
  //             phoneNumbers && phoneNumbers.length > 0
  //               ? schema.required(t('forms.contacts.fields.phoneNumberTypeValidation'))
  //               : schema,
  //           ),
  //       }),
  //     )
  //     .defined(),
  //   title: yup.string(),
  //   vendorId: yup.string().required(t('forms.common.vendorValidation')),
  // });
}
