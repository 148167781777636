/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateInvitationRequest
 */
export interface CreateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    workspaceId?: string;
}

/**
 * Check if a given object implements the CreateInvitationRequest interface.
 */
export function instanceOfCreateInvitationRequest(value: object): value is CreateInvitationRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function CreateInvitationRequestFromJSON(json: any): CreateInvitationRequest {
    return CreateInvitationRequestFromJSONTyped(json, false);
}

export function CreateInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInvitationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'workspaceId': json['workspaceId'] == null ? undefined : json['workspaceId'],
    };
}

export function CreateInvitationRequestToJSON(value?: CreateInvitationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'workspaceId': value['workspaceId'],
    };
}

