/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateReviewRequest,
  ErrorResponse,
  ReviewResponse,
  UpdateReviewRequest,
} from '../models/index';
import {
    CreateReviewRequestFromJSON,
    CreateReviewRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ReviewResponseFromJSON,
    ReviewResponseToJSON,
    UpdateReviewRequestFromJSON,
    UpdateReviewRequestToJSON,
} from '../models/index';

export interface CreateReviewOperationRequest {
    createReviewRequest: CreateReviewRequest;
    xOrganizationId?: string;
}

export interface DeleteReviewByIdRequest {
    id: string;
}

export interface GetAllReviewsRequest {
    engagementId?: string;
    vendorId?: string;
}

export interface GetReviewByIdRequest {
    id: string;
}

export interface UpdateReviewOperationRequest {
    id: string;
    updateReviewRequest: UpdateReviewRequest;
}

/**
 * 
 */
export class ReviewsApi extends runtime.BaseAPI {

    /**
     * Create a new Review
     */
    async createReviewRaw(requestParameters: CreateReviewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewResponse>> {
        if (requestParameters['createReviewRequest'] == null) {
            throw new runtime.RequiredError(
                'createReviewRequest',
                'Required parameter "createReviewRequest" was null or undefined when calling createReview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xOrganizationId'] != null) {
            headerParameters['X-Organization-Id'] = String(requestParameters['xOrganizationId']);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reviews`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReviewRequestToJSON(requestParameters['createReviewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Review
     */
    async createReview(requestParameters: CreateReviewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewResponse> {
        const response = await this.createReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Review by ID
     */
    async deleteReviewByIdRaw(requestParameters: DeleteReviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteReviewById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reviews/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewResponseFromJSON(jsonValue));
    }

    /**
     * Delete a Review by ID
     */
    async deleteReviewById(requestParameters: DeleteReviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewResponse> {
        const response = await this.deleteReviewByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Reviews
     */
    async getAllReviewsRaw(requestParameters: GetAllReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReviewResponse>>> {
        const queryParameters: any = {};

        if (requestParameters['engagementId'] != null) {
            queryParameters['engagementId'] = requestParameters['engagementId'];
        }

        if (requestParameters['vendorId'] != null) {
            queryParameters['vendorId'] = requestParameters['vendorId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReviewResponseFromJSON));
    }

    /**
     * Get Reviews
     */
    async getAllReviews(requestParameters: GetAllReviewsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReviewResponse>> {
        const response = await this.getAllReviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Review by ID
     */
    async getReviewByIdRaw(requestParameters: GetReviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getReviewById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reviews/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewResponseFromJSON(jsonValue));
    }

    /**
     * Get a Review by ID
     */
    async getReviewById(requestParameters: GetReviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewResponse> {
        const response = await this.getReviewByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Review
     */
    async updateReviewRaw(requestParameters: UpdateReviewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateReview().'
            );
        }

        if (requestParameters['updateReviewRequest'] == null) {
            throw new runtime.RequiredError(
                'updateReviewRequest',
                'Required parameter "updateReviewRequest" was null or undefined when calling updateReview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reviews/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReviewRequestToJSON(requestParameters['updateReviewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewResponseFromJSON(jsonValue));
    }

    /**
     * Update a Review
     */
    async updateReview(requestParameters: UpdateReviewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewResponse> {
        const response = await this.updateReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
