/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PhoneNumberDTO } from './PhoneNumberDTO';
import {
    PhoneNumberDTOFromJSON,
    PhoneNumberDTOFromJSONTyped,
    PhoneNumberDTOToJSON,
} from './PhoneNumberDTO';
import type { AddressDTO } from './AddressDTO';
import {
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './AddressDTO';

/**
 * 
 * @export
 * @interface CreateContactRequest
 */
export interface CreateContactRequest {
    /**
     * 
     * @type {AddressDTO}
     * @memberof CreateContactRequest
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    engagementId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    vendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    title?: string;
    /**
     * 
     * @type {Array<PhoneNumberDTO>}
     * @memberof CreateContactRequest
     */
    phoneNumbers?: Array<PhoneNumberDTO>;
}

/**
 * Check if a given object implements the CreateContactRequest interface.
 */
export function instanceOfCreateContactRequest(value: object): value is CreateContactRequest {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    return true;
}

export function CreateContactRequestFromJSON(json: any): CreateContactRequest {
    return CreateContactRequestFromJSONTyped(json, false);
}

export function CreateContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContactRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : AddressDTOFromJSON(json['address']),
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'engagementId': json['engagementId'] == null ? undefined : json['engagementId'],
        'vendorId': json['vendorId'] == null ? undefined : json['vendorId'],
        'title': json['title'] == null ? undefined : json['title'],
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDTOFromJSON)),
    };
}

export function CreateContactRequestToJSON(value?: CreateContactRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': AddressDTOToJSON(value['address']),
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'engagementId': value['engagementId'],
        'vendorId': value['vendorId'],
        'title': value['title'],
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDTOToJSON)),
    };
}

