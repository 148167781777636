/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvitationResponse } from './InvitationResponse';
import {
    InvitationResponseFromJSON,
    InvitationResponseFromJSONTyped,
    InvitationResponseToJSON,
} from './InvitationResponse';

/**
 * 
 * @export
 * @interface InvitationPageResponse
 */
export interface InvitationPageResponse {
    /**
     * 
     * @type {number}
     * @memberof InvitationPageResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<InvitationResponse>}
     * @memberof InvitationPageResponse
     */
    invitations: Array<InvitationResponse>;
}

/**
 * Check if a given object implements the InvitationPageResponse interface.
 */
export function instanceOfInvitationPageResponse(value: object): value is InvitationPageResponse {
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('invitations' in value) || value['invitations'] === undefined) return false;
    return true;
}

export function InvitationPageResponseFromJSON(json: any): InvitationPageResponse {
    return InvitationPageResponseFromJSONTyped(json, false);
}

export function InvitationPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationPageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'invitations': ((json['invitations'] as Array<any>).map(InvitationResponseFromJSON)),
    };
}

export function InvitationPageResponseToJSON(value?: InvitationPageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalCount': value['totalCount'],
        'invitations': ((value['invitations'] as Array<any>).map(InvitationResponseToJSON)),
    };
}

