/* tslint:disable */
/* eslint-disable */
/**
 * VendDex API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreatedBy } from './CreatedBy';
import {
    CreatedByFromJSON,
    CreatedByFromJSONTyped,
    CreatedByToJSON,
} from './CreatedBy';

/**
 * 
 * @export
 * @interface ReviewResponse
 */
export interface ReviewResponse {
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    comment?: string;
    /**
     * 
     * @type {CreatedBy}
     * @memberof ReviewResponse
     */
    createdBy: CreatedBy;
    /**
     * 
     * @type {number}
     * @memberof ReviewResponse
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    vendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    engagementId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReviewResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReviewResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the ReviewResponse interface.
 */
export function instanceOfReviewResponse(value: object): value is ReviewResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('rating' in value) || value['rating'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ReviewResponseFromJSON(json: any): ReviewResponse {
    return ReviewResponseFromJSONTyped(json, false);
}

export function ReviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'createdBy': CreatedByFromJSON(json['createdBy']),
        'rating': json['rating'],
        'vendorId': json['vendorId'] == null ? undefined : json['vendorId'],
        'engagementId': json['engagementId'] == null ? undefined : json['engagementId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ReviewResponseToJSON(value?: ReviewResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'comment': value['comment'],
        'createdBy': CreatedByToJSON(value['createdBy']),
        'rating': value['rating'],
        'vendorId': value['vendorId'],
        'engagementId': value['engagementId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

